var windowsize = jQuery(window).width();

jQuery(document).ready(function () {
	jQuery('.mobile-search').on('click', function(e) { 
    jQuery('body').toggleClass('search-form--open');

		return false;
	});
	
  jQuery('.contact-form__title').on('click', function(e) { 
    jQuery('body').toggleClass('contact-form--open');

		return false;
	});
	
	jQuery('.catalog-menu__btn').on('click', function(e) { 
		jQuery('.catalog-menu__list-container').toggleClass('catalog-menu__list-container--open');
		
		jQuery('body').toggleClass('mega-menu--open');

		return false;
	});
	
	jQuery('.categories__open-btn').on('click', function(e) { 
		jQuery('body').toggleClass('categories-list--open');

		return false;
	});

	jQuery('.categories-list__item--has-children>a').on('click', function(e) { 
		if( windowsize < 1200 ) {
			jQuery(this).parent().toggleClass('sub-categories-list--open');

			return false;
		}
	});

	jQuery('.mobile-menu__open-btn').on('click', function(e) { 
		jQuery('body').toggleClass('menu-open');

		return false;
	});

	jQuery('.mobile-menu__close-btn').on('click', function(e) { 
		jQuery('body').toggleClass('menu-open');

		return false;
	});

	jQuery('.menu-item-has-children>a').on('click', function(e) {
		if( windowsize < 1200 ) {
				var click_item = jQuery(this).parent();

				if (isPaddingClick(this, e)) {
						click_item.toggleClass('submenu-open');

						return false;
				}
		}
	})

	function isPaddingClick(element, e) {
		var style = window.getComputedStyle(element, null);
		var pTop = parseInt( style.getPropertyValue('padding-top') );
		var pRight = parseFloat( style.getPropertyValue('padding-right') );
		var pLeft = parseFloat( style.getPropertyValue('padding-left') );  
		var pBottom = parseFloat( style.getPropertyValue('padding-bottom') );
		var width = element.offsetWidth;
		var height = element.offsetHeight;
		var x = parseFloat( e.offsetX );
		var y = parseFloat( e.offsetY );  
	
		return !(( x > pLeft && x < width - pRight) &&
						 ( y > pTop && y < height - pBottom))
	}
	
	jQuery('.testimonials__tab').on('click', function(e) { 
		var target = '#' + jQuery(this).data("target");

		jQuery('.testimonials__tab').removeClass('testimonials__tab--active');
		jQuery('.testimonials__panel').removeClass('testimonials__panel--active');

		if(jQuery(this).hasClass('testimonials__tab--active') != true){
			jQuery(this).addClass('testimonials__tab--active');
			jQuery(target).addClass('testimonials__panel--active');
		}
	});
	
	jQuery('.featured-products__slider').slick({
		infinite: true,
		arrows: true,
		dots: true,
		speed: 500,
		lazyLoad: 'ondemand',
		autoplay: false,
		autoplaySpeed: 4000,
		useTransform: true,
    pauseOnHover: false,
		adaptiveHeight: true,
		fade: true,
  	cssEase: 'linear',
    prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></button>',
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
					infinite: true,
					dots: true
			  }
			},
			{
			  breakpoint: 992,
			  settings: {
					infinite: true,
					dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
					infinite: true,
					dots: false
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
					infinite: true,
					dots: false
			  }
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		  ]
	});

  jQuery('.testimonials__list').slick({
		infinite: true,
		arrows: true,
		dots: true,
		speed: 500,
		lazyLoad: 'ondemand',
		autoplay: false,
		autoplaySpeed: 4000,
		useTransform: true,
    pauseOnHover: false,
		adaptiveHeight: true,
		fade: true,
  	cssEase: 'linear',
    prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></button>',
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
					infinite: true,
					dots: true
			  }
			},
			{
			  breakpoint: 992,
			  settings: {
					infinite: true,
					dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
					infinite: true,
					dots: false
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
					infinite: true,
					dots: false
			  }
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		  ]
	});
	
	jQuery('.products-slider').slick({
		infinite: true,
		arrows: true,
		dots: false,
		speed: 500,
		lazyLoad: 'ondemand',
		autoplay: false,
		autoplaySpeed: 4000,
		useTransform: true,
    pauseOnHover: false,
    prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></button>',
		slidesToShow: 5,
		slidesToScroll: 1,
	  adaptiveHeight: true,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: true,
				dots: true
			  }
			},
			{
			  breakpoint: 992,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		  ]
	});

	jQuery('#cities').select2({
		minimumResultsForSearch: -1
	});

	if(jQuery('.partners-list').length){
		init_partners();
	}

	if (jQuery(".catalog-menu__list").length) {
		jQuery('.catalog-menu__list').masonry({
				itemSelector: '.catalog-menu__menu-list',
				columnWidth: '.catalog-menu__menu-list',
				percentPosition: true,
				resize: true
		});
	}

	if (jQuery(".testimonials__panel--masonry").length) {
		jQuery('.testimonials__panel--masonry').masonry({
				itemSelector: '.testimonials__item',
				columnWidth: '.testimonials__item',
				percentPosition: true,
				resize: true
		});
	}

	if(jQuery('.products-list__item').length){
		equal_height_products();
	}

	jQuery("body").click(function(e) {
    if (e.target.id != "mega-menu" || jQuery(e.target).parents("#mega-menu").length == 0) {
      jQuery('.catalog-menu__list-container').removeClass('catalog-menu__list-container--open');
		
			jQuery('body').removeClass('mega-menu--open');
    }
	});

	if (jQuery(".wpb_image_grid_ul").length) {
		jQuery('.wpb_image_grid_ul .isotope-item').each(function() {
			console.log(jQuery(this).find("a"));
			jQuery(this).find("a").attr('data-fancybox', 'gallery');
		});
	}
});

jQuery(window).on('resize', function(){
	equal_height_products();
});

function equal_height_products(){
	jQuery('.products-list__item').parent().each(function() {
    var height = 0,
        column = jQuery(this).find('.products-list__item');
			column.each(function() {
					if (jQuery(this).height() > height) height = jQuery(this).height();
			});
			column.height(height);
	});
}

function init_partners(){
	var listItems = jQuery(".partners-list__item");
		
	listItems.each(function(idx, li) {
		var partner = jQuery(li);

		var longitude = partner.find('.partners-list__item-map').data("longitude");
		var latitude = partner.find('.partners-list__item-map').data("latitude");
		var id = partner.find('.partners-list__item-map').attr('id');

		init_map(id, latitude, longitude);
	});
}

jQuery('#cities').on('change', function() {
	var city_id = this.value;

	jQuery('.lds-ring').show();

  jQuery.ajax({
		url: ajax.url,
		type: "POST",
		async: true,
		data: {
				action: "ajax_get_partners",
				city_id: city_id
		},
		success: function(data){
			jQuery("#partners__container").html(data);

			init_partners();

			jQuery('.lds-ring').hide();
		},
		error: function(errorThrown){
			console.log(errorThrown);
		}
	});
});

function init_map(id, latitude, longitude){
	var LatLng = new google.maps.LatLng(longitude,latitude);
		
	var myOptions = {
	  	center: LatLng,
			zoom: 17,
			disableDefaultUI: true,
	  	styles: [
								{
										"featureType": "administrative",
										"elementType": "all",
										"stylers": [
												{
														"saturation": "-100"
												}
										]
								},
								{
										"featureType": "administrative.province",
										"elementType": "all",
										"stylers": [
												{
														"visibility": "off"
												}
										]
								},
								{
										"featureType": "landscape",
										"elementType": "all",
										"stylers": [
												{
														"saturation": -100
												},
												{
														"lightness": 65
												},
												{
														"visibility": "on"
												}
										]
								},
								{
										"featureType": "poi",
										"elementType": "all",
										"stylers": [
												{
														"saturation": -100
												},
												{
														"lightness": "50"
												},
												{
														"visibility": "simplified"
												}
										]
								},
								{
										"featureType": "road",
										"elementType": "all",
										"stylers": [
												{
														"saturation": "-100"
												}
										]
								},
								{
										"featureType": "road.highway",
										"elementType": "all",
										"stylers": [
												{
														"visibility": "simplified"
												}
										]
								},
								{
										"featureType": "road.arterial",
										"elementType": "all",
										"stylers": [
												{
														"lightness": "30"
												}
										]
								},
								{
										"featureType": "road.local",
										"elementType": "all",
										"stylers": [
												{
														"lightness": "40"
												}
										]
								},
								{
										"featureType": "transit",
										"elementType": "all",
										"stylers": [
												{
														"saturation": -100
												},
												{
														"visibility": "simplified"
												}
										]
								},
								{
										"featureType": "water",
										"elementType": "geometry",
										"stylers": [
												{
														"hue": "#ffff00"
												},
												{
														"lightness": -25
												},
												{
														"saturation": -97
												}
										]
								},
								{
										"featureType": "water",
										"elementType": "labels",
										"stylers": [
												{
														"lightness": -25
												},
												{
														"saturation": -100
												}
										]
								}
						]
	};

	var map_data = JSON.parse(ajax.data);
	var marker = map_data.marker;
	var map = new google.maps.Map(document.getElementById(id), myOptions);

	marker = new google.maps.Marker({
	  position: LatLng,
	  map: map,
	  icon: marker
  });
  
  google.maps.event.addDomListener(window, "resize", function () {
    map.panTo(LatLng);
  });
}